import React from "react"
import PropTypes from "prop-types"
//import Dygraph from 'dygraphs';
import LoadingSpinner from "./LoadingSpinner.js"
import moment from 'moment';

const axios = require('axios');

class CardGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }
  componentDidMount() {
		this.setState({ loading: true }, () => {
      axios({
        method: 'get',
        url: `/price_chart?card_id=${this.props.cardId}`,
        responseType: 'json',
      }).then(response => {

        this.setState({ loading: false });

        var graphs = {};

        ["paper", "online"].map(type => {

          var merged_prices = response.data.prices[type];
          var d = `Date, ${response.data.legend.join(",")}`;
          merged_prices.map(price => {
            d += `\n${price.join(', ')}`;
          });

          const colors = (type === "online") ? ['#EE9746', '#2A617B', '#2B8B55', '#C3843C'] : ['#6FB7E7'];
          const fillColor = (type === "online") ? '#F6E6E1' : '#edf7ff';
          const strokeColor = (type === "online") ? '#EE9746' : '#6FB7E7';
          const labelsDiv = `legend-${type}`;

          MTGGoldfishDygraph.bindTabs = function() { };
          MTGGoldfishDygraph.onDocumentReady();

          const options =	{
            height: 200,
            colors: colors,
            // fillGraph: true,
            axisLineColor: 'rgb(150, 150, 150)',
            // deprecated axisLabelColor: 'var(--text-color)',
            strokeWidth: 1,
            includeZero : true,
            gridLineColor: 'rgb(230, 230, 240)',
            axisLabelFontSize: 10,
            showRangeSelector: true,
            labelsDiv: labelsDiv,
            hideOverlayOnMouseOut: false,

            //<% if (DateTime.strptime(merged_prices[merged_prices.length-1][0],"%Y-%m-%d") - DateTime.strptime(merged_prices[0][0],"%Y-%m-%d") > 180) then %>
          //		dateWindow: [<%= date_l %>, <%= date_r %>],
          //	<% end %>
            // deprecated labelsDivStyles: { 'font-weight' : 'normal', 'font-size' : 6, 'textAlign': 'right', 'backgroundColor' : 'transparent' },

            rangeSelectorPlotStrokeColor: strokeColor,
            rangeSelectorPlotFillColor: fillColor,

            /*
            underlayCallback: function(canvas, area, g) {

              const img = new Image();
              //img.src ="<%= asset_path('mtggoldfish-graph-watermark.png')%>";
              //img.src = response.data.watermark_url;
              img.src = "http://192.168.133.128:3000/assets/mtggoldfish-graph-watermark-4df009286cc98200ea7a0887275a0b4de37ee2cec8107761c37712dc497f965d.png"
              // watermark is 92 x 11
              canvas.drawImage(img, canvas.canvas.width - 100, canvas.canvas.height - 80);
              //canvas.drawImage(img, 0, 0);
              console.log("done underlya");
            },
            */
            connectSeparatedPoints: true,
            // deprecated yAxisLabelWidth: 20,
            axes: {
              y: {
                valueFormatter: function(y) {
                  return y.toFixed(1);
                }
              }
            }
          };

          const lastDate = moment(merged_prices[merged_prices.length-1][0],"YYYY-MM-DD")
          const firstDate = moment(merged_prices[0][0],"YYYY-MM-DD")
          if (lastDate.diff(firstDate,'days') > 180) {
            const date_r = moment(merged_prices[merged_prices.length-1][0],"YYYY-MM-DD").valueOf();
            const date_l = date_r - 180*1000*60*60*24;
            options["dateWindow"] = [date_l, date_r];
          }

          graphs[type] = new Dygraph(document.getElementById(`graphdiv-${type}`), d, options);

          $('#priceHistoryTabs a, #dropdown-type-selector a').on('shown.bs.tab', function (e) {
            var target = e.target.hash.replace("#tab-", "");
            const g = graphs[target];
            if (g) {
              g.resize();
            }
          });
        });
      });
    });
  }
  render () {
    return (
      <React.Fragment>
				<div className="price-card-history-container">
          { this.state.loading ?
            <LoadingSpinner/> 
            :
            <>
              <div className="clearfix">
                <h3 className="float-left">Price History</h3>
                <ul className="nav nav-pills" id="dropdown-type-selector">
                  <li className="dropdown nav-item">
                    <a className="paper active nav-link dropdown-toggle" data-toggle="dropdown" href="#">
                      Tabletop
                      <b className="caret"></b>
                    </a>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" data-toggle="tab" href="#tab-paper">Tabletop</a>
                        <a className="dropdown-item" data-toggle="tab" href="#tab-online">MTGO</a>
                    </div>
                  </li>
                </ul>
                <ul className="type-switcher-tabs nav nav-pills deck-type-menu" id="priceHistoryTabs" role="tablist">
                    <li className="btn-type-menu nav-item" role="tab">
                      <a className="nav-link paper" data-toggle="tab" href="#tab-paper">Tabletop</a>
                    </li>
                    <li className="btn-type-menu nav-item" role="tab">
                      <a className="nav-link online" data-toggle="tab" href="#tab-online">MTGO</a>
                    </li>
                </ul>
              </div>
              <button className="btn btn-lg btn-danger" data-html="true" data-placement="top" data-toggle="popover" id="price-event-annotation" type="button">
                <span className="sr-only">Price Graph Annotation</span>
              </button>
              <div className="tab-card tab-content clearfix">
                <div className="tab-card tab-pane" id="tab-online">
                  <div className="legend-single" id="legend-online">&nbsp;</div>
                  <div className="graphdiv-wrapper">
                    <div className="graphdiv" id="graphdiv-online"></div>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="tab-card tab-pane" id="tab-paper">
                  <div className="legend-single" id="legend-paper">&nbsp;</div>
                  <div className="graphdiv-wrapper">
                    <div className="graphdiv" id="graphdiv-paper"></div>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
            </>
          }
        </div>

      </React.Fragment>
    );
  }
}

CardGraph.propTypes = {
  cardId: PropTypes.string
};

export default CardGraph
