import { Controller as n } from "@hotwired/stimulus";
class r extends n {
  add(t) {
		console.log("add");
		console.log(this.templateTarget);
    t.preventDefault();
    const e = String(this.templateTarget.innerHTML).replace(new RegExp(this.recordValue, "g"), new Date().getTime().toString());
		console.log("templateTarget");
		console.log(`replacing ${this.recordValue}`);
		console.log(this.templateTarget);
    this.targetTarget.insertAdjacentHTML("beforebegin", e);
  }
  remove(t) {
    t.preventDefault();
		console.log(`remove: ${this.wrapperSelectorValue}`);
    const e = t.target.closest(this.wrapperSelectorValue);
    console.log("closest");
    console.log(e)
    if (e.dataset.newRecord === "true")
      e.remove();
    else {
      e.style.display = "none";
      const a = e.querySelector(":scope > input[name*='_destroy']");
      console.log("found desetroy");
      console.log(a);
      a.value = "1";
    }
  }
}
r.targets = ["target", "template"];
r.values = {
  wrapperSelector: {
    type: String,
    default: ".nested-form-wrapper"
  },
	record: {
    type: String,
    default: "NEW_RECORD_DEFAULT",
	}
};
export {
  r as default
};
