import React from "react"
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import LoadingSpinner from "./LoadingSpinner"
import CardWidget from "./CardWidget.js"

class DeckSampleHand extends React.Component {
  constructor(props) {
    super(props);
    this.id = uniqueId("deck-sample-hand-");

    this.state = {
    };
  }


  componentDidMount() {
    // initialize cards

    this.newHand();
  }

  render () {
    var listItems = "";
    if (this.state.hand) {
      listItems = this.state.hand.map((card_database, index) =>
        <CardWidget key={index} card_database={card_database}/>
      );
    }
    return (<div className="deck-sample-hand">
      <h4 className="deck-stats-title">Sample Hand</h4>
      <div className="controls text-center mb-2">
        <a href="javascript:void(0)" className="btn btn-secondary mr-1" onClick={this.newHand.bind(this)}>New Hand</a>
        <a href="javascript:void(0)" className="btn btn-secondary" onClick={() => this.drawCards(1)}>Draw</a>
      </div>
      <div className="hand spoiler-card-container">
        {listItems}
      </div>
      </div>);
  }

  drawCards(numCards) {
    const newHand = [ ...this.state.hand ];
    const newCardsInDeck = [ ...this.state.cardsInDeck ];

    if (newCardsInDeck.length > 0) {
      for (var i = 0; i < numCards; i++) {
        newHand.push(newCardsInDeck.shift());
      }

      this.setState({
        hand: newHand,
        cardsInDeck: newCardsInDeck,
      });
    }
  }

  newHand() {
    var cardsInDeck = [];
    for (var entry of this.props.deck.entries) {
      if (this.props.deck.commander === entry.card || this.props.deck.commander_alt === entry.card) {
        // skip commanders
      } else {
        const quantity = entry.quantity;
        for (var i = 0; i < entry.quantity; i++) {
          if (entry.type === "maindeck") {
            cardsInDeck.push({ ...entry.card_database });
          }
        }
      }
    }

		this.shuffle(cardsInDeck);
    this.setState({
      cardsInDeck: cardsInDeck,
      hand: [],
    }, () => { this.drawCards(7); });
  }

	/**
	 * Shuffles array in place. ES6 version
	 * https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
	 * @param {Array} a items An array containing the items.
	 */
	shuffle(a) {
			for (let i = a.length - 1; i > 0; i--) {
					const j = Math.floor(Math.random() * (i + 1));
					[a[i], a[j]] = [a[j], a[i]];
			}
			return a;
	}

}

DeckSampleHand.propTypes = {
  deck: PropTypes.object
};
export default DeckSampleHand
