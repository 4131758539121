export function format_price_with_currency(number, type, decimals = 2, nullPriceValue = null) {

  var value;

  if (number) {
    value = Number.parseFloat(number).toFixed(decimals);
  } else {
    value = nullPriceValue;
  }


  if (value) {
    if (type === "paper") {
      return `$\u00a0${value}`;
    } else if (type === "online") {
      return `${value}\u00a0tix`;
    } else {
      return value;
    }
  } else {
  return "";
  }
}
export function format_percentage(number, decimals = 0) {
  var value;

  if (number) {
    value = Number.parseFloat(100 * number).toFixed(decimals);
    return `${value}%`;
  } else {
    return "";
  }
}
export function get_secondary_image(card) {
  var backside = get_backside(card);
  var result;
  if (backside) {
    result = backside.image_url;
  }
  return result;
}
export function get_backside(card) {
  var secondImage;
  if (card.related_cards) {
    var candidates = card.related_cards.filter(rc => rc.relationship === "mdfc" || rc.relationship === "dfc" || rc.relationship === "meld")
    if (candidates.length > 0) {
      secondImage = candidates[0].card;
    }
  }
  return secondImage;
}
