import React from "react"
import { useEffect, useState, useMemo } from 'react'
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import LoadingSpinner from "./LoadingSpinner"

export class State {

  tiers = new Map()
  observers = new Map()

  tierMetaData = new Map()
  cardMetaData = new Map()

  selected = null


  getTierMetaData(tier) {
    console.log(`getTierMetaData: ${tier}`)
    console.log(this.tierMetaData)
    let result = this.tierMetaData.get(tier)
    console.log("result")
    console.log(result)
    return result
  }

  getCardMetaData(cardId) {
    console.log("getCardMetaData")
    console.log(cardId)
    console.log(this.cardMetaData)
    return this.cardMetaData.get(cardId)
  }

  addTierMetaData(data) {
    this.tierMetaData.set(data.name, data)
    data.items.map(i => this.cardMetaData.set(i.card_id, i.card))

    this.addTier(data.name, data.items.map(i => i.card_id))
  }

  addTier(tier, cardIds) {
    this.tiers.set(tier, cardIds)
  }

  getCardIds(tier) {

    let results= this.tiers.get(tier)
    console.log("getCardIds")
    console.log(tier)
    console.log(results)
    console.log(this.tiers)
    return results || []
  }

  select(tier, cardId) {
    let list = this.tiers.get(tier)
    list.splice(list.indexOf(cardId), 1)
    this.selected = cardId

    console.log("AFTER SELECT")
    console.log(list)
    this.emitChange(tier)
  }

  // moves selected infront of cardId
  move(tier, cardId) {
   if (this.selected) {
    let list = this.tiers.get(tier)
    let insertIndex = list.indexOf(cardId)
    let newList = [
      ...list.slice(0, insertIndex),
      this.selected,
      ...list.slice(insertIndex)
    ]
    this.tiers.set(tier, newList)
    this.selected = null
    this.emitChange(tier)
   }
  }

  emitChange(tier) {
    console.log(`emitChange: ${tier}`)
    let watching = this.observers.get(tier)
    if (watching) {
      console.log("Watching")
      console.log(watching)
      console.log("UPdated card_ids")
      console.log(this.tiers.get(tier))
      watching.map(o => o(this.tiers.get(tier)))
    }

  }

  observe(tier, o) {
    console.log("observe.o")
    //console.log(o)
    let watching = (this.observers.get(tier) || [])
    watching.push(o)
    this.observers.set(tier, watching)
  }

  log() {
    console.log(`SELECTED ${this.selected}`)
    console.log(this.tiers)
  }
}

export const TierItem = ({ card_database }) =>  {
  return (
    <div className="TierItem">
      <p>{card_database.name}</p>
    </div>
  )
}


function handleTierItemClick(tierListState, tierId, cardId) {
  console.log(`Handling click for ${cardId}`);
  tierListState.select(tierId, cardId) 
}

export const Tier = ({ tierID, tierListState }) => {
  const [cardIds, setCardIds] = useState(tierListState.getCardIds(tierID))
  useEffect(() => tierListState.observe(tierID, setCardIds))
  console.log(`Tier.cardIds=${cardIds}`);
  return (
      <React.Fragment>
        <div className="Tier">
          <div className="TierName">
            <h2>{tierListState.getTierMetaData(tierID).name}</h2>
          </div>
          <div className="TierContents">
            { 
              cardIds.map(cardId => {
                return (<div onClick={() => handleTierItemClick(tierListState, tierID, cardId)} className="TierItemWrapper"><TierItem card_database={tierListState.getCardMetaData(cardId)}/></div>)
              })
            }
          </div>
        </div>
      </React.Fragment>
  )
}

export class TierList extends React.Component {
  constructor(props) {
    super(props);
    let s = new State()
    this.props.tierList.tiers.map(tier => {
      s.addTierMetaData(tier)
      //s.addTier(tier.name, tier.items.map(i => i.card_id))
    })
    s.log()


    this.state = { 
      tierListState: s,
    }

  }

	componentDidUpdate() {
	}

  componentDidMount() {
    let s = this.state.tierListState
    s.select("Unranked", "Commodore Guff [CMM]")
    s.log()

    s.move("S", null)
    s.log()

    s.select("Unranked", "Leori, Sparktouched Hunter [CMM]")
    s.log()

    s.move("S", "Commodore Guff [CMM]")
    s.log()
  }

  render () {
    console.log("TierList...")
    return (
        <React.Fragment>
          <h1>{this.props.tierList.name}</h1>
          <div className="TierListContainer">
            <div className="TierListPreviewPane">
              <img src="https://cdn1.mtggoldfish.com/images/h/Tarmogoyf-FUT-672.jpg"/>
            </div>
            <div className="TierList">
              { 
                this.props.tierList.tiers.map(tier => {
                  return (<Tier tierID={tier.name} tierListState={this.state.tierListState}/>)
                })
              }
            </div>
          </div>
        </React.Fragment>
    );

  }
}

TierList.propTypes = {
};
export default TierList
