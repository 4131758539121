import React from "react"
import PropTypes from "prop-types"
import { get_secondary_image } from "./Helpers.js"
class CardLink extends React.Component {
  render () {
    var secondImage = get_secondary_image(this.props.card);

    return (
      <React.Fragment>
        <span className='card_name'>
          <a data-card-id={this.props.card.name} data-full-image={this.props.card.image_url} { ...(secondImage && { "data-full-image1" : secondImage}) } rel="popover" href={this.props.card.links.default}>{this.props.card.display_name}</a>
          { this.props.card.variation && <span className="badge badge-variation">{this.props.card.variation.charAt(0).toUpperCase() + this.props.card.variation.slice(1)}</span> }
          { this.props.card.finish === "foil" && <span className="badge badge-foil">Foil</span> }
          { this.props.card.finish === "foil_etched" && <span className="badge badge-foil">Foil-Etched</span> }
        </span>
      </React.Fragment>
    );
  }
}

CardLink.propTypes = {
  card: PropTypes.object
};
export default CardLink
