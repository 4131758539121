import React from "react"
import PropTypes from "prop-types"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


const MANA_SYMBOLS_ORDER = ["w", "u", "b", "r", "g" ]
const MANA_SYMBOLS_META = {
	w: {
		name: "White",
		color: "#cfcb5f",
		symbol: "w",
	},
	u: {
		name: "Blue",
		color: "#3abbf1",
		symbol: "u",
	},
	b: {
		name: "Black",
		color: "#7d7d7d",
		symbol: "b",
	},
	r: {
		name: "Red",
		color: "#e0615e",
		symbol: "r",
	},
	g: {
		name: "Green",
		color: "#4bcf8f",
		symbol: "g",
	},
}

const MANA_SYMBOLS_COLORS = MANA_SYMBOLS_ORDER.map(s => MANA_SYMBOLS_META[s].color)


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};


class DeckStatsColors extends React.Component {
  constructor(props) {
    super(props);
  }


  componentDidMount() {
  }


	serialize_rechart_data(data, order) {
		var serialized = []

    if (Array.isArray(order) && order.length > 0) {
      for (var key of order) {
        var value = 0;
        if (key in data) {
          value = data[key];
        }
        serialized.push({ name: key, value: value})
      }
    } else {
      for (const [name, value] of Object.entries(data)) {
        serialized.push({ name: name, value: value})
      }
    }

    return serialized;
		
	}

  render () {
    const data = this.serialize_rechart_data(this.props.data, MANA_SYMBOLS_ORDER);
		const renderLineChart = (
			<PieChart width={200} height={200}>
				<Pie
					data={data}
					cx="50%"
					cy="50%"
					labelLine={true}
					//label={renderCustomizedLabel}
					outerRadius={80}
					dataKey="value"
				>

					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={MANA_SYMBOLS_COLORS[index % MANA_SYMBOLS_COLORS.length]} />
					))}
				</Pie>
			</PieChart>);

    return (<div className="deck-stats-colors">
			<h4 className="deck-stats-title">Colors</h4>
      {renderLineChart}
      </div>);
  }
}

DeckStatsColors.propTypes = {
  data: PropTypes.object
};
export default DeckStatsColors
