// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

class r extends Controller {
  static targets = [ "name" ]

  connect() {
    console.log("Hello, Stimilus!", this.element)
    //this.outputTarget.textContent = 'Hello, Stimulus!'
  }

  greet() {
    const element = this.nameTarget
    const name = element.value
    console.log(`Hello, ${name}, ${this.recordValue}`)
  }
}
r.values = {
  record: {
    type: String,
    default: "NEW_RECORD_DEFAULT",
  }
};
export {
  r as default
};
