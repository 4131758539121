import React from "react"
import PropTypes from "prop-types"
class CardImage extends React.Component {
  render () {

    var imgClass = "price-card-image-image";
    if (this.props.htmlClass) {
      imgClass = this.props.htmlClass;
    }

    var filteredImages = this.props.cardImages.filter(ci => ci.format === "jpg" &&  ci.resolution <= 672).sort((a,b) => a.resolution - b.resolution);
    var srcset;
    var defaultSrc = "";

    if (filteredImages.length > 0) {
      srcset = filteredImages.map(ci => `${ci.url} ${Math.trunc(ci.resolution/265.0)}x`).join(", ");
      defaultSrc = filteredImages[0].url;
    } else {
      srcset = this.props.defaultImage;
      defaultSrc = this.props.defaultImage;
    }
    return (
      <React.Fragment>
        <picture>
          <span className="price-card-invisible-label">{this.props.cardName}</span>
          <source type="image/jpg" srcSet={srcset}/>
          <img className={imgClass} alt={this.props.cardName} src={defaultSrc}/>
        </picture>
      </React.Fragment>
    );
  }
}

CardImage.propTypes = {
  cardImages: PropTypes.array,
  cardName: PropTypes.string,
  cardId: PropTypes.string,
  defaultImage: PropTypes.string,
  htmlClass: PropTypes.string,
};
export default CardImage
