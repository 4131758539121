import React from "react"
import PropTypes from "prop-types"
import LoadingSpinner from "./LoadingSpinner"
import { format_price_with_currency } from "./Helpers.js"

const axios = require('axios');
class PriceBoxes extends React.Component {
  static defaultProps = {
    precision: 2,
  }
  render () {
    return (
      <React.Fragment>
				<div className="price-box-container">
          { this.props.loading ?
            <LoadingSpinner/>
            :
            <>
              {this.props.price_paper && 
                <div className="price-box paper">
                  <div className="price-box-type">Tabletop</div>
                  <div className="price-box-price">
                    { format_price_with_currency(this.props.price_paper, "paper", this.props.precision) }
                  </div>
                </div>
              }
              {this.props.price_online &&
                <div className="price-box online">
                  <div className="price-box-type">MTGO</div>
                  <div className="price-box-price">
                    { format_price_with_currency(this.props.price_online, "online", this.props.precision) }
                  </div>
                </div>
              }
            </>
          }
				</div>

      </React.Fragment>
    );
  }
}

PriceBoxes.propTypes = {
  price_paper: PropTypes.number,
  price_online: PropTypes.number,
  precision: PropTypes.number,
};
export default PriceBoxes
