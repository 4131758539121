import React from "react"
import CardsContainer from "./CardsContainer"
import CardGraph from "./CardGraph"
import LoadingSpinner from "./LoadingSpinner"
import PriceBoxes from "./PriceBoxes"
import PropTypes from "prop-types"
import uniqueId from "lodash/uniqueId"
import InnerHTML from 'dangerously-set-html-content'
import moment from 'moment';
//import { trackPromise, usePromiseTracker } from 'react-promise-tracker';


const axios = require('axios');

class CardsFromSet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subset_id: "main_set",
			cards: [],
    }
    this.id = uniqueId("subset-container-");
  }
  componentDidMount() {
    this.loadData(this.props.set_id, this.state.subset_id);
  }

  loadData(set_id, subset_id) {
		this.setState({ loading: true }, () => {
			axios.get(`/sets/${set_id}/cards?subset_id=${subset_id}`)
				.then(response => {
					this.setState({
						loading: false,
						cards: response.data.cards,
						price_paper: response.data.price.paper,
						price_online: response.data.price.online,
						subset_id: subset_id,
					});
				});
		});

  }
  change(event) {
		const subset_id = event.target.value;
    this.loadData("CMR", subset_id);
  }
  render () {
    return (
      <React.Fragment>

				<div className="price-card-name-header">
					<div className="price-card-name-container">
						<h1 className="price-card-name-header-name">{this.props.mtg_set.displayable_name}</h1>
            <div className="set-subset-selector-container">
              <label htmlFor={this.id} className="sr-only">View Cards From: </label>
              <select id={this.id} value={this.state.subset_id} onChange={this.change.bind(this)} className="form-control">
              {
                this.props.subsets.map(s => { return <option key={s.subset_id} value={s.subset_id}>{s.subset_name} ({s.count})</option> })
              }
              </select>
            </div>
					</div>
					<div className="price-card-current-prices">
            <PriceBoxes loading={this.state.loading} price_paper={this.state.price_paper} price_online={this.state.price_online} precision={0} />
          </div>
				</div>

        <div className="set-information-container">
          <div className="set-information-left">
            <img className="set-information-logo" src="https://images1.mtggoldfish.com/mtg_sets/commander_legends_symbol.png"/>
          </div>
          <div className="set-information-details">
            <h4>Release Dates</h4>
            <p>{moment(this.props.mtg_set.release_date_paper).format('LL')}</p>
            <h4>Set Information</h4>
            <p>
              Three-Letter Code: {this.props.mtg_set.name}
              <br/>
              Availability: Tabletop, MTG Arena, MTGO
              <br/>
            </p>
          </div>
          <div className="set-information-content">
            <CardGraph cardId={this.props.set_id}/>
          </div>
        </div>
				<CardsContainer loading={this.state.loading} cards={this.state.cards} view_type="list" />
      </React.Fragment>
    );
  }
}

CardsFromSet.propTypes = {
  subsets: PropTypes.array,
  set_id: PropTypes.string,
  mtg_set: PropTypes.object,
};

export default CardsFromSet
