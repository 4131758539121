import React from "react"
import PropTypes from "prop-types"
import CardWidget from "./CardWidget.js"
import CardLink from "./CardLink.js"
import uniqueId from "lodash/uniqueId"
import LoadingSpinner from "./LoadingSpinner"
import { format_price_with_currency, format_percentage } from "./Helpers.js"

class CardsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.id = "card-container";
    this.raritySortOrder = ["Basic", "Common", "Uncommon", "Rare", "Mythic", "Special"];

    this.columnNames = {
      card_num: "Card Num",
      name: "Card",
      cost: "Mana Cost",
      rarity: "Rarity",
      price_paper: "Tabletop Price",
      dod_delta_paper: "Daily Δ",
      dod_pct_paper: "Daily %",
      wow_delta_paper: "Weekly Δ",
      wow_pct_paper: "Weekly %",
      price_online: "MTGO Price",
      dod_delta_online: "Daily Δ",
      dod_pct_online: "Daily %",
      wow_delta_online: "Weekly Δ",
      wow_pct_online: "Weekly %",
    };

    this.state = {
      view_type: this.props.view_type,
      cards: this.props.cards,
      sort_type: "price_paper",
      sort_order: "auto",
      columns: ["card_num", "name", "cost", "rarity", "price_paper", "price_changes_paper"],
      filters: {},
    };
  }


  static valueForField(card_database, key) {
      if (key === "card_num") {
        return card_database.card_num;
      } else if (key === "name") {
        return card_database.display_name;
      } else if (key === "rarity") {
        return card_database.rarity;
      } else if (key === "price_paper") {
        return card_database.prices.paper.current_price;
      } else if (key === "dod_delta_paper") {
        return card_database.prices.paper.dod_delta;
      } else if (key === "dod_pct_paper") {
        return card_database.prices.paper.dod_pct;
      } else if (key === "wow_delta_paper") {
        return card_database.prices.paper.wow_delta;
      } else if (key === "wow_pct_paper") {
        return card_database.prices.paper.wow_pct;
      } else if (key === "price_online") {
        return card_database.prices.online.current_price;
      } else if (key === "dod_delta_online") {
        return card_database.prices.online.dod_delta;
      } else if (key === "dod_pct_online") {
        return card_database.prices.online.dod_pct;
      } else if (key === "wow_delta_online") {
        return card_database.prices.online.wow_delta;
      } else if (key === "wow_pct_online") {
        return card_database.prices.online.wow_pct;
      }
  }
  static sortCards(cards, sortType, sortOrder, filters) {
    return cards.sort((a,b) => {
      var result = 0;
      if (sortType === "card_num") {
        result = a.card_num - b.card_num;
      } else if (sortType === "name") {
        result = a.display_name.localeCompare(b.display_name);
      } else if (sortType === "rarity") {
        const a_rarity = this.raritySortOrder.findIndex(e => e === a.rarity);
        const b_rarity = this.raritySortOrder.findIndex(e => e === b.rarity);
        result = a_rarity - b_rarity;
      } else if (sortType === "price_paper") {
        result = a.prices.paper.current_price - b.prices.paper.current_price
      } else if (sortType === "dod_delta_paper") {
        result = a.prices.paper.dod_delta - b.prices.paper.dod_delta
      } else if (sortType === "dod_pct_paper") {
        result = a.prices.paper.dod_pct - b.prices.paper.dod_pct
      } else if (sortType === "wow_delta_paper") {
        result = a.prices.paper.wow_delta - b.prices.paper.wow_delta
      } else if (sortType === "wow_pct_paper") {
        result = a.prices.paper.wow_pct - b.prices.paper.wow_pct
      } else if (sortType === "price_online") {
        result = a.prices.online.current_price - b.prices.online.current_price
      } else if (sortType === "dod_delta_online") {
        result = a.prices.online.dod_delta - b.prices.online.dod_delta
      } else if (sortType === "dod_pct_online") {
        result = a.prices.online.dod_pct - b.prices.online.dod_pct
      } else if (sortType === "wow_delta_online") {
        result = a.prices.online.wow_delta - b.prices.online.wow_delta
      } else if (sortType === "wow_pct_online") {
        result = a.prices.online.wow_pct - b.prices.online.wow_pct
      }

      if (sortOrder === "desc") {
        result = result * -1;
      } else if (sortOrder === "auto") {
        const columns = ["price_paper", "price_online", "rarity", "dod_delta_paper", "dod_pct_paper", "wow_delta_paper", "wow_pct_paper", "dod_delta_online", "dod_pct_online", "wow_delta_online", "wow_pct_online"];
        if (columns.includes(sortType)) {
          result = result * -1;
        }
      }

      return result;
    });
  }

	componentDidUpdate() {
		bindPopover();
	}
  componentDidMount() {
    this.setState({
      cards: this.constructor.sortCards(this.state.cards, this.state.sort_type, this.state.sort_order, this.state.filters),
    });
  }


  onChangeSortType(e) {
    const newSortType = e.target.value;
    this.setState({
      sort_type: newSortType,
      cards: this.constructor.sortCards(this.state.cards, newSortType, this.state.sort_order, this.state.filters),
    });
  }

  onChangeSortOrder(e) {
    const newSortOrder = e.target.value;
    this.setState({
      sort_order: newSortOrder,
      cards: this.constructor.sortCards(this.state.cards, this.state.sort_type, newSortOrder, this.state.filters),
    });
  }

  onChangeTypePreference(e) {
    const newTypePreference = e.target.value;
    const newColumns = [];
    for (var key of this.state.columns) {
      var newColumn = key;
      if (key.match(/online|paper|arena/)) {
        newColumn = key.replace(/online|paper|arena/, newTypePreference);
      }
      newColumns.push(newColumn);
    }
    this.setState({
      type_preference: newTypePreference,
      columns: newColumns,
    });
  }

  onChangeColumnSelector(e) {
    const key = e.target.value;
    const checked = e.target.checked;

    var columns = this.state.columns;

    if (checked) {
      columns.push(key);
    } else {
      columns = columns.filter(item => item !== key);
    }

    this.setState({
      columns: columns,
    });
  }


	static getDerivedStateFromProps(props, state) {
		if (props.cards !== state.cards) {
			return {
				cards: CardsContainer.sortCards(props.cards, state.sort_type, state.sort_order, this.state.filters),
			};
		}
		return null;
	}


  sortIconForColumn(column) {
    const baseClasses = "card-container-column-sort fas"
    var sortClass = "fa-sort";
    if (this.state.sort_type == column) {
      if (this.state.sort_order == "asc") {
        sortClass = "fa-sort-up";
      } else if (this.state.sort_order == "desc") {
        sortClass = "fa-sort-down";
      }
    }
    return baseClasses + " " + sortClass;
  }
  onFilterChange(e) {
    const filterKey = e.target.getAttribute("data-filter-key");
    const filterValue = e.target.value;

    const updatedFilters = this.state.filters;

    if (filterValue) {
      updatedFilters[filterKey] = filterValue;
    } else {
      delete updatedFilters[filterKey];
    }

    this.setState({
      filters: updatedFilters,
    });
  }

  sortIconOnClick(e) {
    const column = e.target.getAttribute("data-value");

    var newSortOrder;
    var newSortColumn = this.state.sort_type;

    if (column != this.state.sort_type) {
      newSortColumn = column;
      newSortOrder = "asc";
    } else {
      if (this.state.sort_order == "auto") {
        newSortOrder = "asc";
      } else if (this.state.sort_order == "asc") {
        newSortOrder = "desc";
      } else if (this.state.sort_order == "desc") {
        newSortOrder = "auto";
      }
    }


    this.setState({
      sort_order: newSortOrder,
      sort_type: newSortColumn,
      cards: this.constructor.sortCards(this.state.cards, newSortColumn, newSortOrder, this.state.filters),
    });
  }

  cardIsFiltered(card_database, filters) {
    for (var filterKey in filters) {
      const fieldValue = this.constructor.valueForField(card_database, filterKey);
      const filterValue = filters[filterKey];

      if (filterKey == "rarity") {
        return fieldValue !== filterValue;
      } else if (filterKey == "name") {
        return !card_database.name.toLowerCase().includes(filterValue.toLowerCase());
      } else { // numeric fields

        const field = fieldValue;
        const operator = filterValue; 
        const value = parseFloat(filterValue.replace(/[^\d.-]/g, ''));

        if (operator.includes(">=")) {
          return !(field >= value);
        } else if (operator.includes(">")) {
          return !(field > value);
        } else if (operator.includes("<=")) {
          return !(field <= value);
        } else if (operator.includes("<")) {
          return !(field < value);
        } else {
          return !(field == value);
        }
      }
    }

    return false;
  }

  render () {
    var containerComponent;
    const columns = this.state.columns;
    if (this.state.view_type === "list") {
      var cardDatabaseComponents = this.state.cards.map(function(card_database) {
        var manaCostHTML = "";
        if (card_database.oracle) {
          manaCostHTML = card_database.oracle.mana_cost_html;
        }

        if (!this.cardIsFiltered(card_database, this.state.filters)) {
          return (<tr key={card_database.name}>
            { columns.includes("card_num") && <td className="text-right">{card_database.card_num}</td> }
            <td><CardLink card={card_database}/></td>
            { columns.includes("cost") && <td><div dangerouslySetInnerHTML={ { __html: manaCostHTML } }/></td> }
            { columns.includes("rarity") && <td>{card_database.rarity}</td> }
            { columns.includes("price_paper") && <td className="text-right">{format_price_with_currency(card_database.prices.paper.current_price, "paper")}</td> }

            { columns.includes("price_changes_paper") && <td className="text-right">{format_price_with_currency(card_database.prices.paper.dod_delta, "paper")}</td> }
            { columns.includes("price_changes_paper") && <td className="text-right">{format_percentage(card_database.prices.paper.dod_pct, "paper")}</td> }
            { columns.includes("price_changes_paper") && <td className="text-right">{format_price_with_currency(card_database.prices.paper.wow_delta, "paper")}</td> }
            { columns.includes("price_changes_paper") && <td className="text-right">{format_percentage(card_database.prices.paper.wow_pct, "paper")}</td> }

            { columns.includes("price_online") && <td className="text-right">{format_price_with_currency(card_database.prices.online.current_price, "online")}</td> }
            { columns.includes("price_changes_online") && <td className="text-right">{format_price_with_currency(card_database.prices.online.dod_delta, "online")}</td> }
            { columns.includes("price_changes_online") && <td className="text-right">{format_percentage(card_database.prices.online.dod_pct, "online")}</td> }
            { columns.includes("price_changes_online") && <td className="text-right">{format_price_with_currency(card_database.prices.online.wow_delta, "online")}</td> }
            { columns.includes("price_changes_online") && <td className="text-right">{format_percentage(card_database.prices.online.wow_pct, "online")}</td> }
          </tr>);
        }
      }, this);
      containerComponent = (
        <React.Fragment>
          <div className="table-responsive">
            <table className='card-container-table table table-striped table-sm'>
              <thead>
                <tr>
                  { Object.keys(this.columnNames).map(columnKey => { 
                    var columnName = this.columnNames[columnKey];
                    var columnInclusionKey = columnKey;
                    if (["dod_delta_paper", "dod_pct_paper", "wow_delta_paper", "wow_pct_paper"].includes(columnKey)) {
                      columnInclusionKey = "price_changes_paper";
                    } else if (["dod_delta_online", "dod_pct_online", "wow_delta_online", "wow_pct_online"].includes(columnKey)) {
                      columnInclusionKey = "price_changes_online";
                    }

                    var textAlign = "text-right";
                    if (["name", "cost", "rarity"].includes(columnKey)) {
                      textAlign = "text-left";
                    }
                    if (columns.includes(columnInclusionKey)) {
                      if (columnInclusionKey == "cost") {
                        return (<th key={`column-header-${columnKey}`} className={textAlign}>{columnName}</th>);
                      } else {
                        return (<th key={`column-header-${columnKey}`} className={textAlign}>{columnName} <i data-value={columnKey} onClick={this.sortIconOnClick.bind(this)} className={this.sortIconForColumn(columnKey)}></i></th>);
                      }
                    }
                  }) }
                </tr>
              </thead>
              <tbody>
                <tr>
                  { Object.keys(this.columnNames).map(columnKey => { 
                      var columnInclusionKey = columnKey;
                      if (["dod_delta_paper", "dod_pct_paper", "wow_delta_paper", "wow_pct_paper"].includes(columnKey)) {
                        columnInclusionKey = "price_changes_paper";
                      } else if (["dod_delta_online", "dod_pct_online", "wow_delta_online", "wow_pct_online"].includes(columnKey)) {
                        columnInclusionKey = "price_changes_online";
                      }
                      if (columns.includes(columnInclusionKey)) {
                        if (columnKey == "rarity") {
                          return (<td key={`column-filter-${columnKey}`} ><select data-filter-key={columnKey} onChange={this.onFilterChange.bind(this)} className="form-control">{ [""].concat(this.raritySortOrder).map(rarity => { return <option key={rarity} value={rarity}>{rarity}</option> }) }</select></td>)
                        } else if (columns.includes(columnInclusionKey)) {
                          if (columnInclusionKey != "cost") {
                            return (<td key={`column-filter-${columnKey}`} className="card-container-column-filter"><input data-filter-key={columnKey} onChange={this.onFilterChange.bind(this)} type="text" className="form-control" placeholder="Filter ..."></input></td>);
                          } else {
                            return (<td key={`column-filter-${columnKey}`} className="card-container-column-filter"></td>);
                          }
                        }
                      }
                    })
                  }
                </tr>
                {cardDatabaseComponents}
              </tbody>
            </table>
          </div>
          <p className="text-center text-muted">Showing {this.state.cards.length} Cards.</p>
        </React.Fragment>
      );
    } else if (this.state.view_type === "images") {
      var cardDatabaseComponents = this.state.cards.map(function(card_database) {
        return <CardWidget key={card_database.name} card_database={card_database}/>
        /*
        var extraClass = "";
        var noPrices = false;
        if (card_database.prices.paper.current_price && !card_database.prices.online.current_price) {
          extraClass = "widgetPriceCompact-prices-paper-only"
        } else if (!card_database.prices.paper.current_price && card_database.prices.online.current_price) {
          extraClass = "widgetPriceCompact-prices-online-only"
        } else if (!card_database.prices.paper.current_price && !card_database.prices.online.current_price) {
          extraClass = "widgetPriceCompact-prices-paper-only"
          noPrices = true;
        }

        return (
          <div className="spoiler-card" key={card_database.name}>
            <CardImage cardId={card_database.name} cardName={card_database.display_name} defaultImage={card_database.image_url} cardImages={card_database.images}/>
            <div className="widgetPriceCompact-prices clearfix">
              <div className={`widgetPriceCompact-prices-paper ${extraClass}`}>
                <a href="" className="btn btn-paper widgetPriceCompact-prices-link"> {format_price_with_currency(card_database.prices.paper.current_price, "paper", 2, (noPrices ? "-" : null))} </a>
              </div>
              <div className={`widgetPriceCompact-prices-online ${extraClass}`}>
                <a href="" className="btn btn-online widgetPriceCompact-prices-link"> {format_price_with_currency(card_database.prices.online.current_price, "online")} </a>
              </div>
            </div>
          </div>
          );
          */
      });
      containerComponent = (
        <React.Fragment>
          <div className="spoiler-card-container"> {cardDatabaseComponents} </div>
          <p className="text-center text-muted">Showing {this.state.cards.length} Cards.</p>
        </React.Fragment>
      );
    }

    const options = {
      card_num: "Card Num",
      cost: "Mana Cost",
      rarity: "Rarity",
      price_paper: "Tabletop: Price",
      price_changes_paper: "Tabletop: Price Changes",
      price_online: "MTGO: Price",
      price_changes_online: "MTGO: Price Changes",
    };

    const columnSelector = (<>{Object.keys(options).map(key => { return <div key={key} className="form-check form-check-inline"><input className="form-check-input" type="checkbox" name="columns" value={key} onChange={this.onChangeColumnSelector.bind(this)} checked={columns.includes(key)}/><label className="form-check-label">{options[key]}</label></div> })}</>);

    return (
      <React.Fragment>
        <div className="card-container-controls-container">
        <div className="card-container-controls card-container-view-type">
          <label htmlFor={`${this.id}-view`}>View As:</label>
          <select id={`${this.id}-view`} name="view_options" value={this.state.view_type} onChange={e => this.setState({view_type: e.target.value}) } className="form-control">
            <option value="list">List</option>
            <option value="images">Card Images</option>
          </select>
        </div>
        <div className="card-container-controls card-container-view-type">
          <label htmlFor={`${this.id}-sort_type`}>Sort:</label>
          <select id={`{$this.id}-sort_type`} name="sort_type" value={this.state.sort_type} onChange={this.onChangeSortType.bind(this)} className="form-control">
            <option value="card_num">Card Num</option>
            <option value="name">Card Name</option>
            <option value="rarity">Rarity</option>
            <option value="price_paper">Tabletop: Price</option>
            <option value="dod_delta_paper">Tabletop: Daily Δ</option>
            <option value="dod_pct_paper">Tabletop: Daily %</option>
            <option value="wow_delta_paper">Tabletop: Weekly Δ</option>
            <option value="dod_pct_paper">Tabletop: Weekly %</option>
            <option value="price_online">MTGO: Price</option>
            <option value="dod_delta_online">MTGO: Daily Δ</option>
            <option value="dod_pct_online">MTGO: Daily %</option>
            <option value="wow_delta_online">MTGO: Weekly Δ</option>
            <option value="dod_pct_online">MTGO: Weekly %</option>
          </select>
          <label htmlFor={`${this.id}-sort_order`} className="sr-only">Sort Order:</label>
          <select id={`${this.id}-sort_order`} name="sort_order" value={this.state.sort_order} onChange={this.onChangeSortOrder.bind(this)} className="form-control">
            <option value="auto">Auto</option>
            <option value="asc">Asc</option>
            <option value="desc">Desc</option>
          </select>
        </div>
        <div className="card-container-controls card-container-view-type">
          <label htmlFor={`${this.id}-type_preference`}>Prices:</label>
          <select id={`${this.id}-type_preference`} name="type_preference" value={this.state.type_preference} onChange={this.onChangeTypePreference.bind(this)} className="form-control">
            <option value="paper">Tabletop</option>
            <option value="online">MTGO</option>
          </select>
        </div>
        { this.state.view_type === "list" &&
          <>
            <div className="card-container-controls card-container-column-selector">
              <div className="form-check form-check-inline">
                <label className="column-selector-label">Columns:</label>
              </div>
              <div className="column-selector-container">
                {columnSelector}
              </div>
            </div>
          </>
        }
      </div>
      { this.props.loading ?
        <LoadingSpinner/>
      :
        containerComponent
      }
      </React.Fragment>
    );
  }
}

CardsContainer.propTypes = {
  greeting: PropTypes.string,
  cards: PropTypes.array,
  view_type: PropTypes.string,
};
export default CardsContainer
