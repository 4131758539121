import React from "react"
import PropTypes from "prop-types"
import LoadingSpinner from "./LoadingSpinner.js"
import moment from 'moment';

const axios = require('axios');

const GradedCardListing = ({ listing }) => {
  return (
		<div key={listing.title} className="GradedCardPrice spoiler-card">
			<a href={listing.url} target="_blank">
				<img src={listing.image_url} className="GradedCardPrice-Image mb-2" alt={listing.title}/>
			</a>
			<h4>{listing.title}</h4>
			<div className="GradedCardPrice-Price">
				<a href={listing.url} target="_blank">$ {listing.price}</a>
			</div>
		</div>
  );
};


class GradedCardListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }
  componentDidMount() {
		this.setState({ loading: true }, () => {
      axios({
        method: 'get',
        url: `/graded_card_prices/listings?card_id=${this.props.cardId}`,
        responseType: 'json',
      }).then(response => {
        this.setState({ loading: false, prices: response.data });
      });
    });
  }
  render () {
    return (
      <React.Fragment>
				<div className="GradedCardPricesContainer spoiler-card-container">
          { this.state.loading ?
            <LoadingSpinner/> 
            :
						(this.state.prices.length > 0 ?
								<>
									<h4>Graded Cards By Nostalgium</h4>
									{this.state.prices.map(e => <GradedCardListing listing={e}/>)}
								</>
							:
								<></>
						)
          }
        </div>

      </React.Fragment>
    );
  }
}

GradedCardListings.propTypes = {
  cardId: PropTypes.string
};

export default GradedCardListings
