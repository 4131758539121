import React from "react"
import PropTypes from "prop-types"
class LoadingSpinner extends React.Component {
  render () {
    return (
      <React.Fragment>
        <img src={"/assets/ajax-spinner-4c2ed4d4b65f54b1e9e564cc332d40c82a72e788793468fe7044a10aac16909b.gif"} className="ajax-spinner" alt="Loading Indicator"/>
      </React.Fragment>
    );
  }
}

export default LoadingSpinner
