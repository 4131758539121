import React from "react"
import PropTypes from "prop-types"
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis } from 'recharts'; 

class DeckStatsTypes extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
		const renderLineChart = (
    <RadarChart
      cx="50%"
      cy="50%"
      outerRadius={80}
      width={300}
      height={200}
      data={this.props.data}
    >
      <PolarGrid />
      <PolarAngleAxis dataKey="name" />
      <PolarRadiusAxis />
      <Radar
        name="CardTypes"
        dataKey="count"
        stroke="#8884d8"
        fill="#8884d8"
        fillOpacity={0.6}
      />
    </RadarChart>

    );

    return (<div className="deck-stats-mana-values">
			<h4 className="deck-stats-title">Card Types</h4>
      {renderLineChart}
      </div>);
  }
}

DeckStatsTypes.propTypes = {
  data: PropTypes.array
};
export default DeckStatsTypes
