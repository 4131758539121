import React from "react"
import PropTypes from "prop-types"
import CardImage from "./CardImage.js"

import { format_price_with_currency, format_percentage } from "./Helpers.js"
import { get_backside } from "./Helpers.js"

class CardWidget extends React.Component {
  render () {
    var extraClass = "";
    var noPrices = false;
    if (this.props.card_database.prices.paper.current_price && !this.props.card_database.prices.online.current_price) {
      extraClass = "widgetPriceCompact-prices-paper-only"
    } else if (!this.props.card_database.prices.paper.current_price && this.props.card_database.prices.online.current_price) {
      extraClass = "widgetPriceCompact-prices-online-only"
    } else if (!this.props.card_database.prices.paper.current_price && !this.props.card_database.prices.online.current_price) {
      extraClass = "widgetPriceCompact-prices-paper-only"
      noPrices = true;
    }

    var backside = get_backside(this.props.card_database);
    var cardImage;
    if (backside) {
      cardImage = (
        <div className="price-card-dfc">
          <div className="price-card-dfc-inner">
            <div className="price-card-dfc-spacer">
              <CardImage cardId={this.props.card_database.name} cardName={this.props.card_database.display_name} defaultImage={this.props.card_database.image_url} cardImages={this.props.card_database.images}/>
            </div>
            <div className="price-card-dfc-front">
              <CardImage cardId={this.props.card_database.name} cardName={this.props.card_database.display_name} defaultImage={this.props.card_database.image_url} cardImages={this.props.card_database.images}/>
            </div>
            <div className="price-card-dfc-back">
              <CardImage cardId={backside.name} cardName={backside.display_name} defaultImage={backside.image_url} cardImages={backside.images}/>
            </div>
          </div>
          <button className="btn btn-toggle-dfc btn-secondary" data-toggle="button">
            <span className="fa fa-sync-alt"/>
          </button>
        </div>
      )
    } else {
      cardImage = (<CardImage cardId={this.props.card_database.name} cardName={this.props.card_database.display_name} defaultImage={this.props.card_database.image_url} cardImages={this.props.card_database.images}/>);
    }

    return (
      <div className="spoiler-card">
        <a href={this.props.card_database.links.default}>
          { cardImage }
        </a>
        <div className="widgetPriceCompact-prices clearfix">
          <div className={`widgetPriceCompact-prices-paper ${extraClass}`}>
            <a href={this.props.card_database.links.paper} className="btn btn-paper widgetPriceCompact-prices-link"> {format_price_with_currency(this.props.card_database.prices.paper.current_price, "paper", 2, (noPrices ? "-" : null))} </a>
          </div>
          <div className={`widgetPriceCompact-prices-online ${extraClass}`}>
            <a href={this.props.card_database.links.online} className="btn btn-online widgetPriceCompact-prices-link"> {format_price_with_currency(this.props.card_database.prices.online.current_price, "online")} </a>
          </div>
        </div>
      </div>
      );
  }
}

CardWidget.propTypes = {
  card_database: PropTypes.object
};
export default CardWidget
